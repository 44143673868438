<template>
  <div>
    <div class="section">
      <div class="container c800" style="max-width: 800px">
        <br />

        <!-- h1 choose a name -->
        <h1 class="title is-3">Choose a name for your page</h1>

        <div class="box">
          <b-field
            label="Page name  "
            name="botHandle"
            class="field"
            :type="isUsernameAvailable ? 'is-success' : botHandle.length > 0 ? 'is-danger' : ''"
          >
            <div>
              <b-input
                v-model="botHandle"
                name="botHandle"
                type="text"
                placeholder="ex: hello.world.bot"
                message="You can have a message too"
                @input="checkUsernameAvailability"
              ></b-input>
              <span
                key="bas3"
                v-if="botHandle.length > 0 && !isUsernameAvailable"
                class="icon is-small is-right has-text-danger"
              >
                <i class="fas fa-times"></i>
              </span>
              <span v-if="isUsernameAvailable" key="chk3" class="icon is-small is-right has-text-success">
                <i class="fas fa-check"></i>
              </span>
              <b-tooltip type="is-dark" position="is-right" multilinedNO label=" 5+ letters or numbers, dots allowed">
                <i class="fal fa-question-circle" aria-hidden="true"></i>
              </b-tooltip>
            </div>
          </b-field>
        </div>

        <div class="signup form" style="max-width: 600px">
          <!-- desired username  @ and password -->

          <b-button type="is-dark" @click="submit" :posting="posting" :disabled="!isUsernameAvailable || posting"
            >Create Page</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      handleAvailable: null,
      email: "",
      posting: false,
      botHandle: "",
      //password: "",
      inviteId: "", // Add the invite ID to data
      invite: {},
      isUsernameAvailable: false,
      hasValidInvite: false,
    };
  },
  computed: {
    inviteUrl() {
      return `${window.location.origin}/invite/${this.inviteId}`;
    },
  },
  //on mounted fetch invite id dat from the api using the query secret.
  mounted() {
    //
  },

  methods: {
    /*
    copyUrl() {
      navigator.clipboard.writeText(this.inviteUrl);
      this.$buefy.toast.open({
        message: "Copied to clipboard",
        type: "is-dark",
      });
    },*/
    async checkUsernameAvailability() {
      console.log(23423);
      if (this.botHandle.length === 0) {
        this.isUsernameAvailable = null;
        return;
      }
      this.isUsernameAvailable = false;
      try {
        //  const response = await fetch(`/auth/handle/${this.botHandle}/available`);
        const data = await window.API.isHandleAvailable(this.botHandle);
        console.log(data, "data");
        this.isUsernameAvailable = data;
      } catch (error) {
        console.error("Error checking username availability:", error);
        this.isUsernameAvailable = null;
      }
    },
    isHandleAvailable() {
      // Make a GET request to the server to check if the handle is available
      window.API.isHandleAvailable(this.botHandle).then((res) => {
        // If the request is successful, set the email and invite ID
        this.handleAvailable = res || false;
        //this.email = res.email;
        //this.inviteId = res.inviteId;
      });
    },

    async submit() {
      var handle = this.botHandle;
      const data = {
        handle: handle,
      };

      // Make a POST request to the server to accept the invite and create a new account
      window.API.createBot(data)
        .then((res) => {
          /*
          // If the request is successful, log the user in
          this.$store.main.commit("setUserToken", res.token);
          this.$store.main.commit("setHandle", res.handle);
          this.$store.main.commit("setAvatar", res.avatar); //nada
          this.$store.main.commit("setUserId", res.userId);
          // this.$store.main.commit("setUser", res.user);
          //   this.$router.push({ name: "Home" });*/

          this.$router.push("/bots/" + handle + "?start=true");
        })
        .catch((err) => {
          console.log(err, err.message);
          this.$buefy.toast.open({
            message: "Error: Ensure all informations are valid ", //+ err.message,
            type: "is-danger",
            position: "is-top",
            duration: 9000,
            queue: false,
          });
        });
    },
  },
};
</script>
